// Text Field Css
.textField {
  border-radius: 0;
  box-shadow: inset 4px 4px 3px rgba(0, 0, 0, 0.03);
  flex: 1;
  font-size: 12px;
  color: #234C66;
  font-weight: 600;
  height: 38px;
  width: 100%;
  border-radius: 0 !important;

  input {
    color: #234C66 !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    border-radius: 0 !important;
  }

  // 
  background-color: #ffffff;
  &:disabled {
    background-color: #ffffff;
    color: #234c66;
  }
  input:disabled {
    background-color: #ffffff;
    color: #234c66;
  }
}

.textFieldDark {
  &:extend(.textField);
  background-color: #dfe3ef !important;

  &:disabled {
    &:extend(.textField:disabled);
    background-color: #dfe3ef !important;
    color: #234c66;
  }
  input:disabled {
    &:extend(.textField input:disabled);
    background-color: #dfe3ef !important;
    color: #234c66;
  }
}

.select {
  background-color: #ffffff;
  box-shadow: inset 4px 4px 3px rgba(0, 0, 0, 0.03);
  color: #234C66;
  flex: 1;
  width: 100%;
  height: 38px;
  border-radius: 0 !important;

  :global {
    .ant-select-selector {
      background-color: transparent !important;
      border-radius: 0 !important;
      border: none;
      max-height: 38px !important;
    }

    & span {
      font-size: 12px;
      color: #234C66;
      font-weight: 600 !important;
    }
  }
}

.selectDark {
  &:extend(.select);
  background-color: #dfe3ef !important;
  & > div {
    &:extend(.select > div);
    & span {
      &:extend(.select > div span);
    }
  }
}

.label {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #234C66;
  height: 38px;
  margin: 0 !important;
  padding: 0 !important;
  display: inline-flex;
  align-items: center;
  width: fit-content;
}
// =========================