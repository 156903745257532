.filterArea {
  padding-right: 40px;
  height: 100%;

  .leftContent {
    height: 100%;
    display: flex;
    gap: 10px;
    align-items: center;

    .logoWrapper {
      display: flex;
      align-items: center;
      gap: 16px;
      width: 234px;
      height: 100%;
      padding: 0 16px;
    }
    .searchInput {
      background: #bfcfd9;
      border-radius: 0;
      height: 100%;
      width: 234px;
      font-size: 15px;
      &:placeholder-shown {
        color: #829baa;
      }
    }
  }

  .rightContent {
    height: 100%;
    display: flex;
    gap: 12px;
  }
}

.filterDropdownList {
  border-radius: 4px;
  overflow: hidden;
  .filterDropdownItem {
    padding: 6px 12px;
    cursor: pointer;
    font-size: 12px;
    &:hover {
      background-color: rgba(#F36F21, 0.8) !important;
      color: #fff !important;
    }
  }
}
