@textColor: #8c8888;

.loginForm {
  width: 365px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.textBase {
  font-size: 16px;
}
.textSmall {
  font-size: 12px;
}
.textField {
  border-width: 0 0 1px 0px;
  border-right: none;
  border-bottom: 1px solid @textColor;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 16px;
  box-shadow: none !important;
  
  &::placeholder {
    color: @textColor;
  }

  &:hover {
    border-bottom: 1px solid @textColor;
  }
}

.textFormColor {
  color: @textColor !important;
}
