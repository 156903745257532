@textColor: #8c8888;

.btnSignInGoogle {
  padding: 10px 80px !important;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.textBase {
  font-size: 16px;
}
.textSmall {
  font-size: 12px;
}
.textField {
  border-width: 0 0 1px 0px;
  border-right: none;
  border-bottom: 1px solid @textColor;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 16px;
  box-shadow: none !important;

  & ~ input::placeholder {
    color: @textColor;
  }

  &:hover {
    border-bottom: 1px solid @textColor;
  }
}

.orDividerWrap {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 28px;
  width: 330px;
  color: #bcbcbc;
  .lineDecoration {
    flex: 1;
    height: 1px;
    background: #bcbcbc;
  }
  span {
    color: @textColor;
  }
}

.textFormColor {
  color: @textColor;
}
