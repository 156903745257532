@height-breadcrumb: 100px;
@height-filter: 36px;
@height-menu: 46px;
@rotate-icon: 28deg;
@height-logo-wrapper: 180px;
@height-line-break-header: 12px;

.layoutWrapper {
  min-height: 100vh;
  position: relative;

  &::after {
    content: "";
    display: block;
    width: calc(@height-menu * cos(@rotate-icon));
    height: 100%;
    flex: 1;
    background: #dfe3ef;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
  }

  .contentWrapper {
    padding-right: calc(@height-menu * cos(@rotate-icon) - 4px);
    background: #edeff6;
    height: calc(@height-breadcrumb + @height-line-break-header * 2 + @height-filter );
    display: flex;
    flex-direction: column;
    justify-content: center;

    .contentHeader {
      padding: 34px 48px 0px;
    }

    .contentBody,
    .contentBodyNoneHeader {
      
      height: 100%;
      overflow-y: auto;

      // overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 10px;
        height: 4px;
      }

      &::-webkit-scrollbar-track {
        background: #234c665e;
        border-left: 4px solid #edeff6;
        border-right: 5px solid #dfe3ef;
      }

      &::-webkit-scrollbar-thumb {
        background: #F36F21;
        border-left: 3px solid #edeff6;
        border-right: 3px solid #edeff6;
        border-top: none;
        border-bottom: none;
        border-radius: 0;

        &:hover {
          border: none
        }
      }

      &::-webkit-scrollbar-button {
        display: none;
      }
    }

    .contentBody {
      padding: 0px 48px 34px;
    }

    .contentBodyNoneHeader {
      padding: 34px 48px;
    }
  }

  .headerWrapper {
    background-color: #f6f7fc;

    .breadcrumbWrapper {
      height: @height-breadcrumb;
      padding: 0 64px 0 50px;
      background: #edeff7;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .filterWrapper {
      width: 100%;
      height: @height-filter;
      background: #edeff7;
    }

    .lineBreak {
      display: flex;

      .triangle {
        width: 0;
        height: 0;
        border-left: 36px solid transparent;
        border-bottom: @height-line-break-header solid #d9ddec;
      }

      .line {
        flex: 1;
        background-color: #d9ddec;
      }

      .lineSkew {
        width: calc(@height-menu * cos(@rotate-icon));
        height: @height-line-break-header;
        transform: skew(0, -@rotate-icon);
        margin-top: calc(@height-line-break-header * cos(@rotate-icon) / -1);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        background-color: #d9ddec;
      }
    }

    :global {
      .ant-badge-count {
        min-width: 17px;
        background: #FF2E00;
        box-shadow: none;
        top: -8px;
        transform: translate(70%, -50%);
        font-size: 12px;
      }
    }
  }



  .sidebarWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;

    .imgWrapper {
      height: calc(@height-breadcrumb + @height-line-break-header * 2 + @height-filter + @height-menu * cos(90deg - @rotate-icon));
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f6f7fc;
    }

    .menuWrapper {
      flex: 1;
      background: #edeff6;
      position: relative;

      &::after {
        content: "";
        display: block;
        width: calc(@height-menu * cos(@rotate-icon));
        height: 100%;
        background: #dfe3ef;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 0;
      }

      .menuItem {
        display: flex;
        justify-content: space-between;
        height: @height-menu;
        margin-bottom: 12px;

        .menuItemLeft {
          flex: 1;
          border-bottom: 3px solid #d3d8e3;
          display: flex;
          align-items: center;
          font-weight: 500;
          font-size: 16px;
          cursor: pointer;
        }

        .menuItemRight {
          width: calc(@height-menu * cos(@rotate-icon));
          height: @height-menu;
          transform: skew(0, -@rotate-icon);
          margin-top: calc(@height-menu * cos(90deg - @rotate-icon) / -2);
          border-bottom: 3px solid #c9cdd7;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1;
          cursor: pointer;
        }

        .buttonCollapse {
          width: 39px;
          height: 39px;
          padding: 0;
          display: "flex";
          justify-content: "center";
          align-items: "center";
          border-radius: 0px 10px 10px 0px;
        }
      }
    }
  }
}