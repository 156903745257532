.dialogWrap {
  background: rgba(191, 207, 217, 0.85);
  :global {
    // .ant-modal-content {
    //   padding: 24px;
    //   padding-right: 90px;
    // }
    .ant-col {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #234c66;
    }
  }
}
.textRight {
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: end;
}
.textValueLeft {
  background: #dfe3ef;
  box-shadow: inset 4px 4px 3px rgba(0, 0, 0, 0.03);
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
}
.productDropdown {
  height: 35px;
  font-weight: 400;
  font-size: 12px;
  color: #234c66;
  width: 100%;
  :global {
    .ant-select-selector {
      background-color: transparent !important;
      border: none !important;
    }
  }
}
