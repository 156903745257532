.infoGeneral {
  padding: 30px 36px;
  background-color: #dfe3ef;
  border-radius: 5px;
  font-size: 12px;
  margin-bottom: 50px;
}

.listUserWrapper {
  .listUserHeader {
    background-color: #dfe3ef;
    padding: 0px 20px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    & > span {
      font-size: 16px;
      color: #234c66;
    }
  }

  .listUserContent {
    margin-bottom: 50px;
  }

  .buttonGroup {
    display: flex;
    justify-content: center;
    gap: 100px;
  }
}

.warehouseWrapper {
  margin-bottom: 50px;
}
