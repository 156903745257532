.wrapper {
  background-color: #dfe3ef;
  padding: 10px 18px;
  padding-left: 0;
  width: 100%;
  border-radius: 5px;

  .version {
    background: #8491a6;
    padding: 10px 16px;
    border-radius: 0px 10px 10px 0px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
  }
}
