.siteProductView {
  :global {
    .ant-form-item-explain {
      .ant-form-item-explain-error:first-child {
        margin-top: 10px;
      }
    }
  }
  * {
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    color: #234c66;
  }
  * > span,
  input {
    font-size: 12px !important;
    font-weight: 600 !important;
    color: #234c66;
  }
  .masterProduct {
    :global {
      .ant-select-selection-placeholder {
        font-style: italic;
      }
    }
  }

  .wrapImage {
    display: flex;
    flex-direction: column;
    width: fit-content;
    align-items: center;
  }
  .box1 {
    padding: 25px 52px;
    background: #dfe3ef;
    .title {
      margin-left: 100px;
    }
  }
  .contentRight {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 15px;
  }
  .txtLeft {
    padding-left: 15px;
  }
  .spaceBox {
    margin-top: 9px;
  }
  .boxBottom {
    display: flex;
    justify-content: space-between;
    * {
      color: white;
      font-size: 14px;
      font-weight: 700;
    }
  }
  .space {
    margin-top: 26px;
  }
  .itemCenter {
    align-items: center;
  }
  .flex {
    display: flex;
  }
  .boxQr {
    background: #dfe3ef;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    padding: 26px 60px;
    border-radius: 5px;
  }
  .wrapButtons {
    display: flex;
    width: 50%;
    justify-content: center;
    gap: 96px;
    margin-top: 36px;
  }
  .dropdown {
    > div,
    span,
    input {
      border-radius: 0 !important;
    }
  }
  .wFull {
    width: 100%;
  }

  .leftMarginTop {
    margin-top: 8px;
  }
}
