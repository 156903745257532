.filterArea {
  padding-right: 40px;
  height: 100%;

  .leftContent {
    height: 100%;
    display: flex;
    gap: 10px;
    align-items: center;

    .logoWrapper {
      display: flex;
      align-items: center;
      gap: 16px;
      width: 234px;
      height: 100%;
      padding: 0 16px;
    }
    .searchInput {
      background: #bfcfd9;
      border-radius: 0;
      height: 100%;
      width: 234px;
      font-size: 15px;
      &:placeholder-shown {
        color: #829baa;
      }
    }
  }

  .rightContent {
    height: 100%;
    display: flex;
    gap: 12px;
  }
}

.export_group_button {
  & > ul {
    padding: 0 !important;
    border-radius: 0 !important;
    background-color: #dfe3ef !important;

    li {
      border-radius: 0 !important;
      font-weight: 600 !important;
      padding: 10px 12px !important;
      span {
        color: #424c6a !important;
        font-size: 12px !important;
      }
      &:hover {
        background-color: #acb7d8 !important;
      }
    }
  }
}

.productName {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}