.infoGeneral {
  padding: 30px 36px;
  background-color: #dfe3ef;
  border-radius: 5px;
  font-size: 12px;
  margin-bottom: 16px;
  position: relative;
}

.uploadImageWrapper {
  width: 248px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-radius: 5px;
  background: #dfe3ef;
  font-size: 12px;
  color: #234c66;
  font-weight: 600;
}

.textViewOnly {
  font-size: 12px;
  color: #234c66;
  font-weight: 600;
  // padding: 0px 11px;
}

.buttonGroup {
  display: flex;
  justify-content: center;
  gap: 100px;
  margin-top: 50px;
}
