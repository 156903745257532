.filterArea {
  padding-right: 40px;
  height: 100%;

  .leftContent {
    height: 100%;
    display: flex;
    gap: 10px;
    align-items: center;

    .logoWrapper {
      display: flex;
      align-items: center;
      gap: 16px;
      width: 234px;
      height: 100%;
      padding: 0 16px;
    }
    .searchInput {
      background: #bfcfd9;
      border-radius: 0;
      height: 100%;
      width: 234px;
      font-size: 15px;
      &:placeholder-shown {
        color: #829baa;
      }
    }
  }

  .rightContent {
    height: 100%;
    display: flex;
    gap: 12px;
  }
}
.title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  justify-content: center;
  text-align: center;
  color: #cf3200;
  margin-bottom: 21px;
  width: 100%;
}
