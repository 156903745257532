.avatarCustomWrapper {
  background-image: url("../../assets/images/border_avatar.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  overflow: hidden;
  padding: 4px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}