html,
body {
  font-family: Plus Jakarta Sans, sans-serif;
  font-weight: 400;
}

div,
p,
span {
  word-break: normal;
}

::-webkit-scrollbar {
  background-color: #fff;
  width: 15px;
}

::-webkit-scrollbar-track {
  background-color: #fff;
}
::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 5px solid #fff;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4;
}

::-webkit-scrollbar-button {
  display: none;
}

.break-words {
  word-break: break-word !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.center-element {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tableCustom .ant-table {
  background-color: transparent;
}

.tableCustom .ant-table th {
  color: #000000 !important;
}

.customDropdownSelectAntd .ant-select-item-option {
  color: #234c66;
  font-size: 12px;
  font-weight: 600;
}

.customDropdownSelectAntd .ant-select-item-option-active,
.customDropdownSelectAntd .ant-select-item-option-selected {
  border-radius: 0;
  background-color: #acb7d8 !important;
}

.customInputHasAddon .ant-input-group-addon {
  background-color: #acb7d8;
  border: none;
  outline: none;
  box-shadow: none;
  border-radius: 0;
}

.customInputHasAddon .ant-input-group-addon .ant-select .ant-select-selector {
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.customInputHasAddon input {
  border-start-start-radius: 0 !important;
  border-end-start-radius: 0 !important;
  border-radius: 0 !important;
  border: none;
}

.ant-form-item {
  margin-bottom: 0;
}

.ant-form-item-explain-error {
  font-size: 13px;
  font-style: italic;
}

label {
  height: 38px !important;
}

.h-auto {
  height: auto !important;
}

.ant-form-item-control-input {
  height: 100%;
}

/* Export function */

.print-source {
  display: none;
}

body > .print-source {
  display: block;
  text-align: center;
}

@media print {
  .print-source {
    display: block;
    page-break-before: always;
  }
  .page-break {
    height: 12px;
    padding-top: 12px;
    display: block;
  }
}

/* ============== */
