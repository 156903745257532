.tableCustom {
  table {
    thead {
      tr {
        th {
          text-align: center !important;
          border-bottom: none !important;
          font-size: 11px;
          padding: 11px !important;
          &::before {
            display: none;
          }
        }
        th:last-child {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
        th:first-child {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }
      }
    }
    tbody {
      tr {
        &:hover {
          background-color: transparent !important;
          td {
            background-color: #DFE3EF !important;
          }
        }
        &:nth-child(2n) {
          td {
            background-color: #ffffff;
          }
        }
        td {
          border-top: none !important;
          border-bottom: none !important;
          text-align: center !important;
          font-size: 11px;
          padding: 11px !important;
        }
        td:last-child {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
        td:first-child {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }
      }
    }
  }
}
