.infoGeneral {
  padding: 30px 36px;
  background-color: #dfe3ef;
  border-radius: 5px;
  font-size: 12px;
  margin-bottom: 50px;
  position: relative;

  .buttonWrapper {
    position: absolute;
    bottom: 0;
    right: 0;

    .button {
      padding: 10px 18px;
      border-bottom-right-radius: 5px !important;
      border-top-left-radius: 5px !important;
      font-weight: 600;
    }
  }
}

.buttonGroup {
  display: flex;
  justify-content: center;
  gap: 100px;
}

.item {
  background: #dfe3ef;
  padding: 16px;
  border-radius: 6px;

  .uploadFile {
    display: block;
    width: 100%;
    cursor: pointer;

    & > div {
      width: 100%;
    }

    .contentWrapper {
      display: flex;
      align-items: center;
      padding: 0 10px;
      gap: 8px;

      .content {
        flex: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}

.listProductWrapper {
  .listProductHeader {
    background-color: #dfe3ef;
    padding: 8px 20px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    & > span {
      font-size: 16px;
      color: #234c66;
    }
  }
}