.wrapNoti {
    padding: 10px 10px 0 10px;

    :global {
        .top {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;

            .txtTitle {
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 18px;
                display: flex;
                color: #234C66;
            }

            .mask {
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                display: flex;
                align-items: center;
                color: #606060;
                cursor: pointer;
            }
        }

        .listNoti {
            width: 365px;
            height: 520px;
            overflow: auto;

            b {
                font-weight: 700;
            }

            .itemWrapNoti {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border: 1px solid #E9E9E9;
                border-radius: 10px;
                padding: 20px 29px 23px 14px;
                font-size: 12px;
                line-height: 22px;
                color: #234C66;
                margin-top: 8px;
                p {
                    margin: 0;
                }
            }

            .buttonView {
                width: 50px;
                height: 24px;
                background: #F36F21;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                color: #FFFFFF;
                box-shadow: none;
                border: none;
            }

            .unread {
                width: 8px;
                height: 8px;
                background: #FF2E00;
                border-radius: 50%;
            }
        }

    }
}