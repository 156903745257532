.btn_notched {
  background-image: url("./../../assets/images/background_button.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top right;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  overflow: hidden;
  width: 160px;
  height: 40px;
  font-size: 16px;
  color: #fff;

  &:hover {
    opacity: 0.9;
    cursor: pointer;
  }
}
