.detailWrapper {
  .fileNameWrapper {
    background: #ffffff;
    border-radius: 10px;
    border: 1px dashed #8491a6;
    padding: 14px 16px;
    margin-bottom: 20px;
  }
}

.uploadWrapper {
  position: relative;

  .contentUpload {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 9px;
  }
  .viewLastBtn {
    color: #0094FF;
    cursor: pointer;
    font-weight: 600;
    font-size: 11px;
    text-decoration: underline;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 16px;
  }
}

.pill {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 4px 4px 3px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  border: none;
  color: #424C6A;
  font-weight: 600;
  font-size: 12px;
  display: inline-block;
  padding: 10px 24px;
}