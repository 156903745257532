.table {
  & table {
    thead {
      th,
      td {
        background-color: #fff !important;
        border: none !important;
        box-shadow: none !important;
      }
    }
    tbody {
      tr:first-child {
        td {
          height: 0;
          padding: 0 !important;
        }
      }
      tr {
        &:nth-child(2n) {
          td {
            background-color: #edeff6;
          }
        }
      }
      td {
        border-radius: 0 !important;
      }
    }
  }
}
