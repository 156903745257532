.filterArea {
  padding-right: 40px;
  height: 100%;

  .leftContent {
    height: 100%;
    display: flex;
    gap: 10px;
    align-items: center;

    .logoWrapper {
      display: flex;
      align-items: center;
      gap: 16px;
      width: 234px;
      height: 100%;
      padding: 0 16px;
    }

    .searchInput {
      background: #bfcfd9;
      border-radius: 0;
      height: 100%;
      width: 234px;
      font-size: 15px;

      &:placeholder-shown {
        color: #829baa;
      }
    }
  }

  .rightContent {
    height: 100%;
    display: flex;
    gap: 12px;
  }
}

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin-bottom: 16px;
  width: 100%;
  color: #234c66;
}

.listProduct {
  padding-top: 16px;
  border-top: 1px solid #dfe3ef;
}

.wrapForm {
  background: #dfe3ef;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #234c66;
  position: relative;
  :global {
    .ant-col {
      font-weight: 600;
      font-size: 12px;
    }
  }
}

.textRight {
  text-align: right;
  color: #234c66;
  font-weight: 600;
  font-size: 12px;
}

.dropdown {

  >div,
  span,
  input {
    border-radius: 0 !important;
    color: #234c66;
    font-weight: 600 !important;
    font-size: 12px;
  }
}

.wrapAdd {
  margin-bottom: 24px;

  .statusWrapper {
    display: flex;
    align-items: center;
    gap: 16px;

    .dropdownStatus span {
      color: #d95100 !important;
    }
  }

  .flexGap {
    color: #234c66;
    font-weight: 600 !important;
    font-size: 12px;
    display: flex;
    gap: 4px;
    align-items: center;
  }
}

.statusInput {
  background: #dfe3ef;
  font-weight: 400;
  font-size: 12px;
  color: #234C66;
  border-radius: 0;
  margin-top: 4px;
}

.buttonAdd {
  background: black;
  color: white;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  line-height: 16px;
}

.wrapButtonAdd {
  cursor: pointer;
}

.textAdd {
  font-weight: 400;
  font-size: 14px;
  text-decoration-line: underline;
  text-underline-offset: 2px;
  color: #192eeb;
}

.flex {
  display: flex;
}

.buttonGroup {
  display: flex;
  justify-content: center;
  gap: 100px;
  margin-top: 50px;
}

.inputOrderQuantity {
  background: #DFE3EF;
  box-shadow: inset 4px 4px 3px rgba(0, 0, 0, 0.03);
  border-radius: 0;

  :global {
    .ant-input-number-input {
      text-align: center;
    }
  }
}

.viewLastBtn{
  color: #192EEB;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  text-decoration: underline;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 16px;
}